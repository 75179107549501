<template>
  <div>
    <calendarBreadcrumbs :link="'dashboardFormBuilder'" />
    <div class="flix-form-group">
      <transition name="flixFadeIn">
        <formBuilder :key="key" v-if="data" :form="data.form" />
      </transition>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    formBuilder () { return import('@/components/formBuilder/preview') },
    calendarBreadcrumbs () { return import('@/components/assets/calendarBreadcrumbs') }
  },
  props: {},
  data () {
    return {
      data: false,
      key: new Date().getTime()
    }
  },
  watch: {
    '$route.params.id' (id) { this.getData(); this.key = new Date().getTime() },
    data: {
      handler (form) { this.saveAssistent() },
      deep: true
    }
  },
  methods: {
    saveAssistent () {
      if (typeof this.$route.params.id === 'undefined') {
        return false
      }
      this.$store.dispatch('setAssistent', {
        ID: this.$route.params.id,
        data: this.data,
        callback: function (ret) {
        }
      })
    },
    getData () {
      this.$flix_post({
        url: 'assistent/get_data',
        data: {
          user: this.$store.getters.user.md5_id,
          ID: this.$route.params.id
        },
        callback: function (ret) {
          if (ret.data[0]) {
            this.data = ret.data[1]
          }
        }.bind(this)
      })
    }
  },
  mounted () {
    if (this.$route.params.id) {
      this.getData()
      return false
    }
    this.$router.push({ name: 'dashboardFormBuilder', params: { id: this.$store.getters.assistents[0].ID } })
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
